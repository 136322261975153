<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=6 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">{{formtitle}}<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="shippingaddressform" :label-position="labelPosition" :rules="rules"
                    label-width="100px" class="elform" ref="elshippingform">
                    <el-form-item class="formL" label="地址信息" prop="ap">
                      <el-cascader ref="addressCascader" style="width:180px" class="city" :options="city"
                        v-model="shippingaddressform.ap" @change="oncitycascader" clearable></el-cascader>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="Address">
                      <el-input v-model="shippingaddressform.Address"></el-input>
                    </el-form-item>
                    <el-form-item label="收货人" prop="contactuser">
                      <el-input v-model="shippingaddressform.contactuser"></el-input>
                    </el-form-item>

                    <el-form-item class="phone" label="手机号码" prop="contactphone">
                      <el-input v-model="shippingaddressform.contactphone"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                      <el-checkbox v-model="shippingaddressform.IsDefault">设置为默认收货地址</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elshippingform')">保存</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  usershippingaddressgetlist,
  usershippingaddressinsertorupdate,
  usershippingaddressdel,
  usershippingaddresssetdefault,
  usershippingaddressgetdetail
} from '@/api/user';
import userleft from '@/components/userleft.vue';
import { cityjson } from '@/utils/cityjson.js';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      formtitle:'新增收货地址',
      city: cityjson,
      phoneposition: [
        {
          id: 86,
          name: '中国大陆+86',
        },
      ],
      dialogImageUrl: '',
      businessend: '',
      labelPosition: 'right',
      firstShow: true,
      show: false,
      height: '600',
      dialogimgVisible: false,
      certtypedata: [],
      isauthresult: 1,

      province: [],
      city: cityjson,
      provinceid: 0,
      provincename: '',
      cityid: 0,
      cityname: '',
      countyid: 0,
      countyname: '',
      citylist: [],
      shippingaddresslist: [],
      addressid: 0,
      shippingaddressform: {
        contactuser: '',
        contactphone: '',
        ProvinceId: '',
        ProvinceName: '',
        CityId: '',
        CityName: '',
        CountyId: '',
        CountyName: '',
        Address: '',
        Alias: '',
        IsDefault: 0,
        ap: [],
        id: 0,
      },
      rules: {
        contactuser: [{ required: true, message: '请填写收货人' }],
        contactphone: [{ required: true, message: '请填写联系电话' }],
        ap: [{ required: true, message: '请选择区域' }],
        Address: [{ required: true, message: '请填写详细收货地址' }],
      },
    };
  },
  methods: {
    oncitycascader() {},
       oninitshippingaddress(item) {
      this.dialogVisible = true;
      this.shippingaddressform.contactuser = item.contactuser;
      this.shippingaddressform.contactphone = item.contactphone;
      this.shippingaddressform.ProvinceId = item.provinceId;
      this.shippingaddressform.CityId = item.cityId;
      this.shippingaddressform.ProvinceName = item.provinceName;
      this.shippingaddressform.CityName = item.cityName;
      this.shippingaddressform.CountyName = item.countyName;
      this.shippingaddressform.CountyId = item.countyId;
      this.shippingaddressform.Address = item.address;
      this.shippingaddressform.Alias = item.alias;
      this.shippingaddressform.IsDefault = item.isDefault;
      this.shippingaddressform.id=item.id;
      this.shippingaddressform.ap= [""+item.provinceId,""+item.cityId, ""+item.countyId];
      if(item.isDefault==0){
        this.shippingaddressform.IsDefault=false;
      }else{
        this.shippingaddressform.IsDefault=true;
      }
    },
    onusershippingaddressgetdetail() {
      var that = this;
      usershippingaddressgetdetail({ id: this.addressid }).then((res) => {
        if (res.data.code === 1) {
         this.oninitshippingaddress(res.data.result);
        }
      });
    },
    oncitycascader(item) {
      var labels = this.$refs.addressCascader.getCheckedNodes()[0].pathLabels;
      this.shippingaddressform.ProvinceName = labels[0];
      this.shippingaddressform.CityName = labels[1];
      this.shippingaddressform.CountyName = labels[2];
      this.shippingaddressform.ProvinceId = item[0];
      this.shippingaddressform.CityId = item[1];
      this.shippingaddressform.CountyId = item[2];
      console.log(item);
      // this.province = item;
      // this.cityid = item[1];
    },
    onsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.shippingaddressform.IsDefault) {
            this.shippingaddressform.IsDefault = 1;
          } else {
            this.shippingaddressform.IsDefault = 0;
          }
          usershippingaddressinsertorupdate(this.shippingaddressform).then(
            (res) => {
              if (res.data.code == 1) {
                this.$router.push({ path: '/user/shipping/shippinglist' });
              } else {
                this.$message.error(res.data.message);
              }
            }
          );
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created() {
    this.addressid = this.$route.query.addressid;
    if(this.addressid){
    this.formtitle='修改收货地址';
    }
    this.onusershippingaddressgetdetail();
  },
  mounted() {
    screenLeft;
    const self = this;
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 90px;
  font-size: 15px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset >>> form.elform .formaddress .el-input {
  width: 120px;
  margin-right: 10px;
}
.accountset >>> form.elform .el-form-item__content .el-input {
  width: 100%;
}
.accountset >>> form.elform .el-form-item__content .el-cascader {
  width: 100% !important;
}
/* .accountset .phone{
  display: inline-block;
}
.accountset >>> .phone .el-form-item__content .el-select{
  width: 80px;
}
.accountset >>> .phone .el-form-item__content .el-select .el-input input{
  width: 100px;
  padding: 5px;
  padding-right: 0px;
  font-size: 13px;
}
.accountset >>> .phone  .phone-item{
  margin-left: 30px;
}
.accountset >>> .phone  .el-form-item__content .el-input{
  width: 250px !important;
} */
</style>
